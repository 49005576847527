import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { blockHomeNumbers } from '../data/data'
import { usePartners } from '../hooks/use-partners'
import { makeRequest, changelanguages } from '../helpers'
import ScrollSection from '../components/scroll-section/scroll-section'

const Test = () => {
  const [homeData, setHomeData] = useState([])
  const { loading, partners } = usePartners()
  const [loadingHome, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const getHomeData = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: {
        lang: changelanguages(i18n.language),
        slug: 'home',
      },
    }).then((resp) => {
      setHomeData(resp.data[0])
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getHomeData()
  }, [i18n.language])
  const viewProps = {
    blockHomeNumbers,
    loading,
    partners,
    loadingHome,
    data: homeData,
    t,
  }
  return (
    <div>
      <ScrollSection {...viewProps} />
    </div>
  )
}

export default Test
